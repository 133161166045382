body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.appModal .rs-modal-header {
  padding-right: 0;
}
.review-content li.rs-rate-character.rs-rate-character-full {
  margin-right: 0 !important;
}
.appModal .rs-modal-header span.rs-modal-header-close {
  position: absolute;
  top: 15px;
  right: 15px;
}
.appModal.dark .rs-modal-content {
  background-color: #14161f !important;
}
.appModal.dark .rs-modal-title {
  color: #fff;
}
.form-widget li.rs-rate-character.rs-rate-character-full,
li.rs-rate-character.rs-rate-character-half,
li.rs-rate-character.rs-rate-character-empty {
  margin-right: 0 !important;
}
ul.rs-rate {
  display: flex;
  gap: 5px;
}
.rsuite-color-picker {
  box-shadow: none !important;
}
.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  display: flex !important;
}

.rs-btn .rs-checkbox-checker {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: unset;
  padding-left: 22px;
}

.rs-btn .rs-checkbox-wrapper {
  top: 5px;
  left: 0;
}
.review-checkbox {
  width: 20px !important;
}

.review-checkbox .rs-checkbox-checker {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.review-checkbox .rs-checkbox-wrapper {
  left: 2px;
}

.rsuite-color-picker .rsuite-color-picker-review {
  left: 1px !important;
  top: 1px !important;
  height: calc(100% - 1px * 2) !important;
  width: calc(100% - 1px * 2) !important;
  -webkit-border-top-left-radius: 3px !important;
  -webkit-border-bottom-left-radius: 3px !important;
  -moz-border-radius-topleft: 3px !important;
  -moz-border-radius-bottomleft: 3px !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rs-uploader-trigger.rs-uploader-trigger-customize {
  cursor: pointer;
}

.rs-dropdown-menu,
.rs-picker-menu {
  z-index: 9999999 !important;
}

.rs-modal-backdrop {
  z-index: 1051 !important;
}

.rs-modal-wrapper {
  z-index: 1052 !important;
}
.rs-avatar-image {
  object-fit: cover;
}
.rs-uploader-trigger-btn {
  border-radius: 10px;
}
.rs-message-body {
  display: flex;
  align-items: center;
  gap: 10px;
}
.no-shadow div {
  outline: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.no-shadow input.rs-picker-toggle-textbox {
  font-size: 14px;
  background-color: #f5f5f5 !important;
}
.no-shadow .rs-picker-toggle-value {
  color: #6a6a6a !important;
  font-size: 14px;
}
.no-shadow .rs-picker-toggle-placeholder {
  font-size: 14px;
}
.reply-form .rs-form-control,
.reply-form .rs-input {
  width: 100% !important;
}
.rs-modal-title {
  text-overflow: unset !important;
  white-space: initial !important;
}
.rs-btn-icon-with-text {
  padding: 10px 16px 10px 10px !important;
}
.add-gap {
  display: flex;
  gap: 5px;
}
.big-icon .rs-message-icon-wrapper svg {
  font-size: 20px;
}
iframe#webpack-dev-server-client-overlay {
  display: none;
}

.name .rs-toggle-presentation {
  background-color: #0017e8 !important;
}
.settings .rs-toggle-checked .rs-toggle-presentation {
  background-color: #0ba32e !important;
}
.price-switcher.rs-toggle {
  z-index: 2;
}
.price-switcher .rs-toggle-presentation {
  color: rgb(50, 79, 190);
  background-color: rgb(235, 238, 249);
}

.price-switcher .rs-toggle-presentation:hover {
  background-color: rgb(235, 238, 249);
}

.price-switcher.rs-toggle-checked .rs-toggle-presentation,
.price-switcher.rs-toggle-checked .rs-toggle-presentation:hover {
  background-color: #324fbe;
  /* color: rgb(50, 79, 190) !important;
  background-color: rgb(235, 238, 249) !important;
  border: 1px solid rgb(50, 79, 190); */
}

.price-switcher.rs-toggle-checked .rs-toggle-presentation::after {
  color: #ebeef9;
}

.profile .rs-btn-subtle:hover,
.profile .rs-btn-subtle:focus,
.profile .rs-btn-subtle:active {
  background-color: transparent !important;
}
.profile .rs-dropdown-toggle-caret {
  display: none;
}
.profile .rs-dropdown-toggle {
  padding-right: 12px !important;
}
.profile .rs-dropdown-toggle svg {
  margin: 0 !important;
}
.profile .rs-dropdown-item-divider {
  margin: 0;
}

.price-switcher .rs-toggle-inner {
  /* width: 60px; */
}

.redemption-code .rs-input-group-lg.rs-input-group-inside > .rs-input {
  padding-right: 10px !important;
}
/* .review-content button {
  padding-bottom: 7px !important;
} */

.rs-drawer-wrapper:has(> div.tutorial-drawer) {
  z-index: 1050;
}

.rs-drawer-wrapper:has(> div.feedback-form) {
  z-index: 1052;
}
.rs-drawer-backdrop {
  /* z-index: 1051 !important; */
  background-color: rgb(from var(--rs-gray-900) r g b / 90%) !important;
}
.signup-slider {
  height: 100% !important;
  background-color: #fff !important;
}
/* .select-all-wrapper .rs-checkbox-checker {
  padding-top: 12px;
}

.select-all-wrapper .rs-checkbox-control {
  left: 0;
}

.select-all-wrapper {
  margin-top: 20px;
} */

.filtering-nav {
  position: relative;
}

.filtering-nav .filterButton {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.rs-drawer-header .rs-drawer-header-close {
  display: none;
}
.usage .rs-progress-line {
  padding-left: 0;
}

.usage .rs-progress-line .rs-progress-info {
  display: none;
}

.rs-modal-wrapper:has(> div.upgrade-modal) {
  z-index: 999999999999 !important;
}
.rs-modal-footer {
  padding-top: 15px;
}
.rs-modal-header {
  padding-top: 0 !important;
  padding-bottom: 0px !important;
}
.reviews-metric .rs-progress-line {
  padding: 2px 0;
}
.rs-picker {
  border-radius: 20px;
}
.rs-picker .rs-picker-toggle {
  background: transparent !important;
}
.rs-input.rounded,
.rs-input-group.rounded {
  border-radius: 20px !important;
}

.rs-modal-body-close {
  display: none;
}
.rs-uploader-file-item-picture-text {
  cursor: pointer;
}
.review-form-modal.rs-modal-sm {
  max-width: 500px;
}

.add-question-modal .rs-modal-header {
  padding-left: 40px;
}

.add-question-modal .rs-modal-header h4 {
  margin-top: 4px;
}

.bubble-menu {
  padding: 0px;
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: 0 0 20px 4px #9aa1b126, 0 4px 80px -8px #24282f40,
    0 4px 4px -2px #5b5e6926;
  border-radius: 5px;
  transition-duration: 50ms;
  display: flex;
  align-items: stretch;
  gap: 2px;
}

.bubble-menu button {
  padding: 5px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 5px;
}

.bubble-menu button.is-active {
  background-color: #f3f4f6;
}

mark {
  font-weight: 400;
}

.add-review-modal .rs-modal-content,
.add-question-modal .rs-modal-content,
.convert-to-product-modal .rs-modal-content {
  padding-left: 0;
  padding-right: 0;
}
.reviews-filter-drawer .rs-drawer-header {
  padding: 20px !IMp;
}

.email-details-drawer.rs-drawer {
  box-shadow: none !important;
}

.email-details-drawer .rs-drawer-content {
  /* background-color: #eceff3; */
}
.email-details-drawer .rs-drawer-body-close {
  display: none;
}

.onboarding-drawer .rs-drawer-dialog {
  position: relative;
  width: calc(100% - 20px);
  height: 100%;
  padding: 10px;
}

.onboarding-drawer .rs-drawer-content {
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.onboarding-drawer .rs-drawer-body-close {
  position: absolute;
  top: 15px;
  right: 15px !important;
  left: unset;
  z-index: 99999;
}

.onboarding-drawer .rs-drawer-body {
  padding: 0;
}

.onboarding-drawer .small-logo {
  max-width: 25px;
  border-radius: 50%;
}

.custom-toast .rs-message-container {
  background-color: #000000 !important;
  color: #fff;
}

.custom-toast .rs-message-container button,
.custom-toast .rs-message-container .rs-message-icon svg {
  color: #fff !important;
}

.custom-toast .rs-message-body {
  color: #fff !important;
}
